.redactor-clips-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.redactor-clips-list li {
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 4px;
}
.redactor-clips-list span {
    white-space: nowrap;
    background: rgba(0,125,255, .75);
    color: #fff;
    display: inline-block;
    padding: 3px 6px;
    line-height: 1;
    border-radius: 4px;
    cursor: pointer;
}