@use "sass:meta" as ---eyygoxs6v1;@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@keyframes fadeOut {
    from { opacity: 1; }
    to   { opacity: 0; }
}

.redactor-animate-hide { display: none !important; }
.redactor-fadeIn  { opacity: 0; animation: fadeIn .2s ease-in-out; }
.redactor-fadeOut { opacity: 1; animation: fadeOut .2s ease-in-out; }
;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:3740");