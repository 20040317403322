@use 'sass:math';
@import 'assets/styles/core';
@import 'assets/styles/fonts';

$z-index-toolbar: 100;
$z-index-toolbar-wrraper-air: 1001;
$base-font-family: $body-font-family;
$font-size: $global-font-size;
$base-line: 1.6;
$header-margin-bottom: rem-calc(7, 12);
$header-styles: (
  small: (
    'h1': ('font-size': rem-calc(30, 12)),
    'h2': ('font-size': rem-calc(22, 12)),
    'h3': ('font-size': rem-calc(16, 12)),
    'h4': ('font-size': rem-calc(15, 12)),
    'h5': ('font-size': rem-calc(14, 12)),
    'h6': ('font-size': rem-calc(13, 12)),
  ),
);
$headings-base-line: 1.4;
$headings-font-weight: normal;
$color-text: $black;
$color-headings: $black;
$color-link: map_get($foundation-palette, primary);
$color-link-hover: map_get($foundation-palette, alert);

@import './_scss/redactor';

.redactor-in {
  @include foundation-global-styles;
  @include foundation-typography-base;
  @include foundation-typography-helpers;
  @include foundation-text-alignment;
  @include foundation-flex-grid;
  @include foundation-flex-classes;
  @include foundation-float-classes;
  @include foundation-table;
  @include foundation-button;
  @include foundation-thumbnail;
  @include foundation-responsive-embed;

  figure {
    margin: 0 auto $global-margin;
    text-align: center;

    &.figure-left {
      margin: 0 $global-margin $global-margin 0;
      float: left;
      clear: both;
    }

    &.figure-right {
      margin: 0 0 $global-margin $global-margin;
      float: right;
      clear: both;
    }
  }

  figcaption {
    color: $dark-gray;
    font-style: italic;
    font-size: 13px;
    text-align: center;
  }

  .limg {
    text-align: center;
    float: left;
    margin: 0 $global-margin math.div($global-margin, 2) 0;
  }

  .rimg {
    text-align: center;
    float: right;
    margin: 0 0 math.div($global-margin, 2) $global-margin;
  }

  .cimg {
    margin: $global-margin;
    text-align: center;
  }

  .box-shadow-lb {
    box-shadow: -2px 2px 10px rgb(0 0 0 / 75%);
  }

  .box-shadow-rb {
    box-shadow: 2px 2px 10px rgb(0 0 0 / 75%);
  }

  .info {
    background-color: #ffc;
    display: block;
    margin: $global-margin 0;
    padding: $global-padding;
    width: auto;
    overflow: hidden;
  }

  .tizer {
    display: block;
    width: auto;
    margin: $global-margin 0;
    padding: $global-padding;
    background-color: #f8f6e7;
    border-radius: $global-radius;

    p:last-child {
      margin-bottom: 0;
    }
  }

  table.table {
    margin: 0 0 $global-margin;
    line-height: 18px;
    border: 2px solid $medium-gray;
  }

  .table {
    caption {
      font-size: 12px;
      font-weight: $global-weight-bold;
      text-align: right;
      line-height: 24px;
      color: $light-black;
    }

    th {
      background-color: #f2f2f2;
      border-bottom: 1px $light-black solid;
      font-size: 12px;
      font-weight: $global-weight-bold;
      padding: 4px 5px;
      text-align: center;
    }

    td {
      border-bottom: 1px $light-gray solid;
      font-size: 13px;
      padding: 4px 5px;

      &.ltl {
        font-size: 11px;
      }
    }

    p {
      margin-bottom: 0;
    }

    &-row-white {
      background-color: $white;

      &:hover {
        background-color: #f0f0f0;
      }
    }

    &-row-gray {
      background-color: #f4f4f4;

      :hover {
        background-color: #f0f0f0;
      }
    }
  }

  div[data-panorama] {
    background-color: #eee;
    height: 100px;
    margin: 0 auto 1rem;
    width: 100%;

    &::before {
      content: 'Фотография 360°';
      display: block;
      line-height: 100px;
      text-align: center;
    }
  }
}
